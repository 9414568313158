// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/aguinaldo_web_diciembre_landing_1.jpg";
import { SalWrapper } from "react-sal";
import { Card, Col, Container, Row } from "react-bootstrap";
import LogoEquity from "../imgs/logos-fci/Logo_Fondos_OUT_FB_EQUITY_AZ.png";
import LogoPERF from "../imgs/logos-fci/Logo_FCI_PERFIII.svg";
import LogoAcciones from "../imgs/logos-fci/Logo_FCI_ACC.svg";
import LogoAhorroUSD from "../imgs/logos-fci/Logo_FCI_AH_DOL.svg";
import LogoAZ_FCI_AH_DOL from "../imgs/logos-fci/LogoAZ_FCI_AH_DOL.png";
import Logo_FCI_PERFII from "../imgs/logos-fci/Logo_FCI_PERFII.png";
import LogoRentaFija from "../imgs/logos-fci/Logo_FCI_RF_DL.svg";
import VideoInvertir from "../imgs/cap_aguinaldo.jpg";
import Slider from "react-slick";
import "./inverti-tu-aguinaldo.css";
import Faq from "../components/faq/faq";
import { apiRequest } from "../apiRquest/apiRequest";
import { graphql, useStaticQuery, Link } from "gatsby";


const slickSettings = {
  centerMode: true,
  centerPadding: "0px",
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1, 
  dots: false,
  infinite: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1, // Actualiza también para mostrar de 1 en 1 en pantallas más pequeñas
      },
    },
  ],
};
const slickSettingsMobile = {
  centerMode: true,
  centerPadding: "0px",
  initialSlide: 0,
  slidesToShow: 2,
  slidesToScroll: 1, 
  dots: false,
  infinite: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1, // Actualiza también para mostrar de 1 en 1 en pantallas más pequeñas
      },
    },
  ],
};

const onsSlickSettings = {
  centerMode: true,
  centerPadding: "0px",
  initialSlide: 0,
  slidesToShow: 2,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const bannerDesc =
  '<h2 class="bannerDesc">Conocé qué hacer con tus pesos y todas las alternativas que tenemos para maximizar tu aguinaldo de forma inteligente</h2>';
const pageTitle = "Invertí tu aguinaldo | Balanz";
const pageDesc =
  "Conocé cómo podés invertir hoy tu aguinaldo en Balanz: Fondos Comunes de Inversión, Bonos, CEDEARs y cómo dolarizarte operando Dólar MEP.";

const Aguinaldo = () => {
  const FaqsData = useStaticQuery(graphql`
    {
      allFaqs(
        filter: { category_id: { in: 18 } }
        sort: { order: ASC, fields: [category_id, order] }
      ) {
        nodes {
          category_id
          id
          answer
          question
        }
      }
    }
  `);

  const [faq, setFaq] = useState({
    list: FaqsData.allFaqs.nodes,
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
        console.log('hola')
        console.log(isMobile)
        if(window.innerWidth < 991){
            setIsMobile(true)
        }
    };
   
    window.addEventListener("resize", handleResize);
    handleResize(); // Llamada inicial para verificar el tamaño actual

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(isMobile)
  useEffect(() => {
    apiRequest
      .get("/v1/faq/18")
      .then((res) => {
        const data = [];
        res.data.data.forEach((d) => {
          data.push({
            question: d.question,
            answer: d.answer,
          });
        });
        setFaq((old) => ({ ...old, list: data }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setFaq]);
  const page = (
    <>
      <ProductBanner
        bannerContainerClassName="bannerAguinaldo"
        bannerTrasparecy={null}
        productTitle="<b><span class='underline'>¿En&nbsp;qué&nbsp;invertir&nbsp;</span><br class='d-inline d-sm-none' />el&nbsp;aguinaldo?<b>"
        productTitleClassName="bbottom"
        bannerImage={Banner}
        bannerImageClassName="eventosBannerImage"
        textContainerClassName="textContainer"
        bannerText={null}
        bannerTextClassName="bannerText1"
        bannerImageMobile={Banner}
        mobile={false}
        bannerDesc={bannerDesc}
      />
      {/*<SalWrapper>*/}
      <section className="text-center mt-5">
        <Container>
          <h2 style={{ color: "var(--primary)", textTransform: "none" }}>
            <strong>#Aguinaldo</strong>Inteligente
          </h2>
        </Container>
      </section>
      <section className="fullwidth invertir-fondos-section product-description">
        <Container>
          <h2 className="text-left">
          2 Fondos Comunes de Inversión para diversificar tu aguinaldo
          </h2>
           {
            !isMobile && (
                <Slider {...slickSettings} className="list-inversion">
           <div className='list-inversion-item'>
                                <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1 perfomance'>
                                            <img style={{maxWidth: "420px"}} src={LogoAZ_FCI_AH_DOL} className='obf-cover' alt="FCI Ahorro Dólares" />
                                        </Card.Title>
                                        <Card.Text className="mb-3">
                                        Busca la apreciación del capital a través de la inversión en una cartera diversificada de 
                                        <span><strong>activos de renta fija corporativa de alta calidad crediticia</strong></span> y denominada en dólares.
                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                            <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Fija
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: USD 100
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn btn-primary btn-hover' href='/inversiones/fondos/ahorro-en-dolares/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJBSFVTREEiLCJwYW5lbCI6IjEwIiwiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0FIT1JST1VTRC1GQ0ktV0VCLTIwMjQwNjEyIiwgImNhbXBhaWduX2lkIjoiQUdVSU5BTERPQUhPUlJPVVNELUZDSS1XRUItMjAyNDA2MTIifX0'>Invertir</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1'>
                                            <img style={{maxWidth: "420px"}} src={Logo_FCI_PERFII} alt="Perfomance II" />
                                        </Card.Title>
                                        <Card.Text className="mb-3">
                                        Busca la apreciación del capital mediante la <span><strong>inversión en Lecaps</strong></span> de corto plazo y activos de liquidez inmediata.
                                        <br></br>Tiene liquidez T+0, con un corte al día, pagando al cierre del mercado.

                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                        <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Fija
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: $1.000
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn thin btn-primary btn-hover' href='/investment-ideas/fondo-balanz-performance-ii/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IlBFUjJEIiwicGFuZWwiOiIxMCIsImlkQ2FtcGFpZ24iOiJBR1VJTkFMRE9QRVIyLUZDSS1XRUItMjAyNDA2MTIiLCAiY2FtcGFpZ25faWQiOiJBR1VJTkFMRE9QRVIyLUZDSS1XRUItMjAyNDA2MTIifX0'>Invertir</a>
                                        </div>


                                    </Card.Body>
                                </Card>
                            </div>
                           
            </Slider>
            )
           }
          {
            isMobile && (
                    
                <Slider {...slickSettingsMobile} className="list-inversion">
                  <div className='list-inversion-item'>
                            <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1 perfomance'>
                                            <img style={{maxWidth: "420px"}} src={LogoAZ_FCI_AH_DOL} className='obf-cover' alt="FCI Ahorro Dólares" />
                                        </Card.Title>
                                        <Card.Text className="mb-3">
                                        Busca la apreciación del capital a través de la inversión en una cartera diversificada de 
                                        <span><strong>activos de renta fija corporativa de alta calidad crediticia</strong></span> y denominada en dólares.

                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                            <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Fija
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: USD 100
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn btn-primary btn-hover' href='/inversiones/fondos/ahorro-en-dolares/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJBSFVTREEiLCJwYW5lbCI6IjEwIiwiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0FIT1JST1VTRC1GQ0ktV0VCLTIwMjQwNjEyIiwgImNhbXBhaWduX2lkIjoiQUdVSU5BTERPQUhPUlJPVVNELUZDSS1XRUItMjAyNDA2MTIifX0'>Invertir</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                           
                            </div>
                   <div className='list-inversion-item'>
                                <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1'>
                                            <img style={{maxWidth: "420px"}} src={Logo_FCI_PERFII} alt="Perfomance II" />
                                        </Card.Title>
                                        <Card.Text className="mb-3">
                                        Busca la apreciación del capital mediante la <span><strong>inversión en Lecaps</strong></span> de corto plazo y activos de liquidez inmediata.
                                        <br></br>Tiene liquidez T+0, con un corte al día, pagando al cierre del mercado.
                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                        <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Fija
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: $1.000
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn thin btn-primary btn-hover' href='/investment-ideas/fondo-balanz-performance-ii/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IlBFUjJEIiwicGFuZWwiOiIxMCIsImlkQ2FtcGFpZ24iOiJBR1VJTkFMRE9QRVIyLUZDSS1XRUItMjAyNDA2MTIiLCAiY2FtcGFpZ25faWQiOiJBR1VJTkFMRE9QRVIyLUZDSS1XRUItMjAyNDA2MTIifX0'>Invertir</a>
                                        </div>


                                    </Card.Body>
                                </Card>
                            </div>
                            
                            
                </Slider>
                    
            )
          }
        </Container>
      </section>

      
      <section className="fullwidth obligaciones-negociables-section pb-3">
        <Container>
          <div className="section-content py-2">
            <p className="volanta" style={{ color: "var(--secondary)" }}>
              MERCADO SECUNDARIO
            </p>
            {/*<h6 className="topic base-text">MERCADO SECUNDARIO</h6>*/}
            <h2 className="titulo mb-5">
              <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                Obligaciones Negociables en Dólares
              </span>
            </h2>

            <Slider
              {...onsSlickSettings}
              className="slider-obligaciones-negociales"
            >
              <div className="slider-item">
                <Card className="card-obligaciones-negociables">
                  <Card.Header>
                    <Card.Title as="h3">
                      Invertí en <br />
                      Pan American Energy 
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p className="base-text-custom mb-2">
                      <strong>
                        {" "}
                        Destacada empresa energética de América Latina, enfocada en la exploración, producción y comercialización de petróleo y gas.

                      </strong>
                    </p>
                    <ul className="mb-4">
                      <li clasName="base-text-custom">
                        <strong>Perfil:</strong> Conservador
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ticker:</strong> PNXCO
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Vencimiento:</strong> 30/04/2032
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Cupón:</strong> 8.50%
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Pago:</strong> Semestral
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Moneda:</strong> Dólares
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ley:</strong> New York
                      </li>
                    </ul>

                    <a
                      href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJQTlhDTyIsInBhbmVsIjoiMjciLCJwbGF6byI6IjEiLCJpZFBsYXpvIjoiMSIsImlkQ2FtcGFpZ24iOiJBR1VJTkFMRE9QQUUtT04tV0VCLTIwMjQwNjEyIiwgImNhbXBhaWduX2lkIjoiQUdVSU5BTERPUEFFLU9OLVdFQi0yMDI0MDYxMiJ9fQ"
                      target="blank"
                      className="btn-link"
                    >
                      Invertir&nbsp;&nbsp;
                    </a>
                  </Card.Body>
                </Card>
              </div>
              <div className="slider-item">
                <Card className="card-obligaciones-negociables">
                  <Card.Header>
                    <Card.Title as="h3">
                      Invertí en <br />
                      YPF

                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p className="base-text-custom mb-2">
                      <strong>
                      Empresa argentina líder en el sector energético, dedicada a la exploración, explotación, destilación, distribución y producción de energía eléctrica, gas, petróleo y derivados de hidrocarburo.
                      </strong>
                    </p>
                    <ul className="mb-4">
                      <li clasName="base-text-custom">
                        <strong>Perfil:</strong> Moderado
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ticker:</strong> YMCIO 
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Vencimiento:</strong> 30/06/2029
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Cupón:</strong> 9.00%
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Pago:</strong> Semestral
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Moneda:</strong> Dólares
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ley:</strong> New York
                      </li>
                    </ul>

                    <a
                      href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJZTUNJTyIsInBhbmVsIjoiMjciLCJwbGF6byI6IjEiLCJpZFBsYXpvIjoiMSIsImlkQ2FtcGFpZ24iOiJBR1VJTkFMRE9ZUEYtT04tV0VCLTIwMjQwNjEyIiwgImNhbXBhaWduX2lkIjoiQUdVSU5BTERPWVBGLU9OLVdFQi0yMDI0MDYxMiJ9fQ"
                      target="blank"
                      className="btn-link"
                    >
                      Invertir&nbsp;&nbsp;
                    </a>
                  </Card.Body>
                </Card>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
      <section className="fullwidth invertir-aguinaldo">
        <Container>
          <div className="section-content py-5">
            <h2 className="titulo mb-3">
              <span style={{ color: "#ffffff", fontSize: "110%" }}>
                Evento Online: Aguinaldo Inteligente ¿En qué invertirlo?
              </span>
            </h2>
            <h5 className="mb-2">
              <span style={{ color: "#ffffff", fontSize: "600" }}>
              Conocé más sobre estos y otros instrumentos financieros en nuestra capacitación online 
              </span>
            </h5>
            
          </div>
          <a
            className="btn-custom btn thin btn-secondary"
            target="_blank"
            href="https://www.youtube.com/watch?v=AgjcKeQwvtM"
          >
            Ver más
          </a>
        </Container>
      </section>

      <section className="mt-5 mb-2">
        <Container>
          <h2 className="text-left" style={{ color: "var(--secondary" }}>
          Armá tu portfolio a largo plazo invirtiendo en el mundo a través de CEDEARs
          </h2>
        </Container>
      </section>
      {/* <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey">
        <Container>
          <div className="section-content">
          <Row>
                  
                  <Col md={6}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                   PERFIL MODERADO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                   Pack Acciones Value II
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  >Está compuesto por compañías que cotizan a valoraciones{" "}
                  <br />
                  relativamente bajas en relación a sus beneficios y potencial
                  de crecimiento. <br />
                  Suelen pagar dividendos y cuentan con bajo endeudamiento.
                  </span>
                </p>
                  </Col>
                  <Col md={6}
                  className="d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                 <a
                  className="btn-custom btn thin btn-secondary"
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stVkFMVUUgSUkgfCBQZXNvcyBDRURFQVIiLCJwYW5lbCI6IjM4IiwicGxhem8iOiIyIiwiaWRQbGF6byI6IjIifX0"
                >
                  Invertir
                </a>
                  </Col>
                </Row>
          </div>
        </Container>
      </section> */}
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey">
        <Container>
          <div className="section-content">
          <Row>
                  
                  <Col md={6}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                   PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                   Google (GOOGL)
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  >Lidera en productos y servicios de Internet, software y dispositivos electrónicos. 
                  La empresa domina la distribución de la funcionalidad de IA y posee una participación de mercado del 90% en la actividad de búsqueda.


                  </span>
                </p>
                  </Col>
                  <Col md={6}
                  className="d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                 <a
                  className="btn-custom btn thin btn-secondary"
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJHT09HTCIsInBhbmVsIjoiNyIsInBsYXpvIjoiMSIsImlkUGxhem8iOiIxIiwiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0dPT0dMLUNFREVBUi1XRUItMjAyNDA2MTIiLCAiY2FtcGFpZ25faWQiOiJBR1VJTkFMRE9HT09HTC1DRURFQVItV0VCLTIwMjQwNjEyIn19"
                >
                  Invertir
                </a>
                  </Col>
                </Row>
          </div>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey mt-4">
        <Container>
          <div className="section-content">
          <Row>
                  
                  <Col md={6}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                    PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                   Deere (DE)
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  > Fabrica y distribuye equipos en todo el mundo de agricultura, construcción, silvicultura y cuidado del césped. 
                  Se sumó a la revolución tecnológica con su unidad de negocio de software para la producción.
                  
                  </span>
                </p>
                  </Col>
                  <Col md={6}
                  className="d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                 <a
                  className="btn-custom btn thin btn-secondary"
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJERSIsInBhbmVsIjoiNyIsInBsYXpvIjoiMSIsImlkUGxhem8iOiIxIiwiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0RFLUNFREVBUi1XRUItMjAyNDA2MTIiLCAiY2FtcGFpZ25faWQiOiJBR1VJTkFMRE9ERS1DRURFQVItV0VCLTIwMjQwNjEyIn19"
                >
                  Invertir
                </a>
                  </Col>
                </Row>
          </div>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey mt-4">
        <Container>
          <div className="section-content">
          <Row>
                  
                  <Col md={6}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                    PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                   Qualcomm (QCOM)
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  > Empresa de tecnología inalámbrica proveedora de circuitos integrados con Apple y Samsung como mayores clientes.
                  Fue pionero y tuvo un rol fundamental en el desarrollo del 3G y 4G y lo está teniendo en el 5G.
                  
                  
                  </span>
                </p>
                  </Col>
                  <Col md={6}
                  className="d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                 <a
                  className="btn-custom btn thin btn-secondary"
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJRQ09NIiwicGFuZWwiOiI3IiwicGxhem8iOiIxIiwiaWRQbGF6byI6IjEiLCJpZENhbXBhaWduIjoiQUdVSU5BTERPUUNPTS1DRURFQVItV0VCLTIwMjQwNjEyIiwgImNhbXBhaWduX2lkIjoiQUdVSU5BTERPUUNPTS1DRURFQVItV0VCLTIwMjQwNjEyIn19"
                >
                  Invertir
                </a>
                  </Col>
                </Row>
          </div>
        </Container>
      </section>
      {/* <section className="fullwidth invertir-fondos-section product-description">
                    <Container >
                        <h2
                            className="text-center"
                        >Invertí en el mundo a través de CEDEARs </h2>

                        <Slider {...slickSettings} className='list-inversion slider-inversion'>
                            <div className='list-inversion-item'>
                                <Card className='card-inversion-cedears'>
                                    <Card.Body>
                                        <Card.Title className='mb-4 card-title-cedears'>
                                            <h4>Taiwan Semiconductor</h4>
                                            <span>Ticker: TSM</span>
                                        </Card.Title>
                                        <Card.Text className="mb-3 text-body-cedears">
                                        Se trata de una empresa estratégica, con una captación del 90% del mercado de chips de alta tecnología.
                                        </Card.Text>
                                        <Card.Text className="mb-3 text-body-cedears">
                                        Debería pertenencer en cualquier portafolio de mediano a largo plazo. El precio actual es una oportunidad de iniciar posiciones, con múltiplos bajos y un dividend yield de 2.25%.
                                        </Card.Text>
                                        <Card.Text className="mb-3 text-body-cedears">
                                            <ul className='list-data-2 mb-3'>
                                                <li>
                                                &nbsp;
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="text-center-boton">
                                            <a target='_blank' className='btn-custom btn thin btn-secondary mx-auto' href='https://clientes.balanz.com/'>INVERTIR</a>
                                        </div>
                                    
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='list-inversion-item'>
                                <Card className='card-inversion-cedears'>
                                    <Card.Body>
                                        <Card.Title className='mb-4 card-title-cedears'>
                                            <h4>Bunge Limited</h4>
                                            <span>Ticker: BNG</span>
                                        </Card.Title>
                                        <Card.Text className="mb-3 ">
                                        Cuenta con una importante presencia en el mercado global de commodities agrícolas y fertilizantes. Tuvo un gran desempeño este año, aunque todavía cotiza debajo de sus máximos.
                                        </Card.Text>
                                        <Card.Text className="mb-3 text-body-cedears">
                                        La compañía sigue creciendo, recientemente adquirió el 49% de la francesa BZ Group. Además, el múltiplo PE por debajo de los dos dígitos y un dividendo atractivo, aseguran un precio defensivo.
                                        </Card.Text>
                                        <div className="text-center">
                                            <a target='_blank' className='btn-custom btn thin btn-secondary mx-auto' href='https://clientes.balanz.com/'>INVERTIR</a>
                                        </div>


                                    </Card.Body>
                                </Card>
                            </div>



                        </Slider>
                    </Container>
                </section> */}

      

      <section className="mt-5 mb-2">
        <Container>
          <h2 className="text-left" style={{ color: "var(--secondary" }}>
            <span><strong>Aguindaldo Invertido = Aguinaldo Inteligente</strong></span>
            <br></br>Invertí en CEDEARs, invertí en el mundo
          </h2>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey">
        <Container>
          <div className="section-content">
            <div className="content-section">
                <Row>
                  
                  <Col md={12}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                   PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                   Pack de CEDEARs Quant Selection 
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  >
                    Su objetivo es seleccionar CEDEARs de empresas líderes de diversas industrias cuya relación entre retorno y volatilidad sea mejor al del índice S&P 500 en un periodo determinado. Este Pack fue creado utilizando criterios estrictamente cuantitativos que surgen de la historia de precios, rendimientos y volatilidades de las compañías que lo integran. 
                  </span>
                </p>
                  </Col>
                  </Row>
            </div>
            <div className="mt-5">
              <Row>
                  <Col md={12}
                  className="d-flex flex-column align-items-center justify-content-center justify-content-md-end"
                  >
                  <a
                  className="btn-custom btn thin btn-secondary "
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stIFFVQU5UIHwgUGVzb3MgQ0VERUFSIiwicGFuZWwiOiIzOCIsInBsYXpvIjoiMSIsImlkUGxhem8iOiIxIiwiaWRDYW1wYWlnbiI6IkFHVUlOQUxET1FVQU5ULVBBQ0stV0VCLTIwNzIwMjQtREVFUExJTksiLCJjYW1wYWlnbl9pZCI6IkFHVUlOQUxET1FVQU5ULVBBQ0stV0VCLTIwNzIwMjQtREVFUExJTksifX0"
                > Invertir </a>
                  </Col>
                  
                </Row>
              </div>
          </div>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo">
        <Container>
          <div className="section-content">
            <div className="content-section">
                <Row>
                <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
          DOLARIZARTE ES SIMPLE
          </p>
          <h2 className="titulo mb-md-4">
                  <span style={{ color: "var(--primary)", fontSize: "110%" }}>
                  Comprá Dólar MEP
                  </span>
                 </h2>
                  <Col md={12}>
                    <p  style={{ 
                      color: "var(--primary)", 
                      fontWeight: "900",
                      fontSize: "1.6rem",
                      }}>
                          Convertí tus pesos en dólares, de forma rápida y segura
                 </p>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "500",
                      fontSize: " 123%",
                    }}
                  >
                    Con un solo click, podés iniciar tu inversión y aprovechar esta oportunidad financiera única. 
                    Dólar MEP se ha convertido en una de las opciones más populares para dolarizar ahorros, 
                    debido a la accesibilidad y seguridad. Operar en Dólar MEP es una forma conveniente y 
                    confiable de manejar tus recursos y dolarizarte.


                  </span>
                </p>
                  </Col>
                  </Row>
                  <Row>
                  <Col md={12}
                  className="d-flex align-items-center justify-content-center mt-5"
                  >
                  <a
                  className="btn-custom btn thin btn-secondary "
                  target="_blank"
                  href="https://clientes.balanz.com/auth/login"
                > Invertir </a>
                  </Col>
                </Row>
            </div>
          </div>
        </Container>
      </section>
      {/* <section className="mt-5 mb-2">
        <Container>
        
        </Container>
      </section> */}
     

      {/* <section className="fullwidth invertir-section">
                    <Container>
                        <div className="info-invertir d-flex flex-column flex-md-row justify-content-center">
                            <Col xs={12} md={7} lg={6} className="d-flex align-content-stretch">
                                <div className="card-invertir ratio ratio-2x1 position-relative">
                                    <div className="section-content position-absolute">
                                        <h2 className='titulo mb-md-4'><span style={{color:"#ffffff", fontSize:"110%"}}>¿En qué puedo invertir?</span></h2>
                                        <h2 className='as-base-text mt-0'><span style={{lineHeight:"1.25", color:"#ffffff", fontWeight:"600", fontSize:" 100%" }}>En este video te contamos en qué podés invertir hoy en la bolsa</span></h2>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={5} lg={4} className="p-4 pt-0 p-md-5 position-relative" style={{zIndex:"2"}}>
                                <a href='https://youtu.be/LQ7u_84bBoo' target='_blank' className="btn-como-invertir">
                                    <img className="img-fluid" src={VideoInvertir} alt="Como invertir" />
                                </a>
                            </Col>
                        </div>
                    </Container>
                </section>


                <section className="fullwidth forma-ahorrar-section text-center">
                    <Container>
                        <div className="section-content py-5">
                        <h2 className="as-base-text mb-4 text-start text-md-center">CAMBIÁ TU FORMA DE AHORRAR</h2>
                        <p className="titulo mb-5 text-start text-md-center">
                           <span style={{color:"var(--primary)", fontSize:"100%"}}>
                            Abrí tu cuenta <span className="highlightbold primary-color">100% online</span> en&nbsp;menos de 1 minuto <br className="d-none d-sm-inline" />
                            y empezá a invertir </span>
                        </p>
                        <h4><a className='btn-custom btn btn-secondary thin' target='_blank' href='https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1386'>ABRÍ TU CUENTA DE INVERSIÓN</a></h4>
                        </div>
                    </Container>
                </section> */}
      <section className="fullwidth preguntas-frecuentes">
        <Container>
          <Row>
            <Faq faq={faq} customClass={"aguinaldo-faq"} />
          </Row>
          <p className="mb-4">
            El destinatario deberá evaluar por sí mismo la conveniencia de la
            inversión en los presentes valores negociables.
          </p>
          <p className="mb-4">
            Balanz Capital Valores S.A.U. y/o Balanz Sociedad Gerente de Fondos
            Comunes de Inversión S.A.U. no brindan garantías ni aseguran
            rentabilidades ni resultados de los presentes valores negociables.
            Para mayor información, contáctese con nuestros asesores.
          </p>
        </Container>
      </section>
    </>
  );

  return (
    <main>
      <Layout
        title={pageTitle}
        description={pageDesc}
        childrem={page}
        category={"aguinaldos"}
      ></Layout>
    </main>
  );
};

// Step 3: Export your component
export default Aguinaldo;
